<template>
    <div>
      <Card>
        <template #header>İller</template>
        <div class="d-flex align-items-center justify-content-between">
          <div class="d-flex align-items-center">
            <Input placeholder="Ara.." v-model="search" class="mr-2" />
          </div>
          <div class="d-flex align-items-center">
            <b-form-group>
              <b-button  @click="addFileModal=true"><i class="flaticon2-plus mb-1"></i>Yeni İl Ekle</b-button>
            </b-form-group>
          </div>
        </div>
      
      <List
        :header="header"
        :data="items"
        :pagination="pagination"
        :loading="loading"
        @updatePage="updatePage"
        moduleName="Sistem Kullanıcı Listesi"
        :moduleCreateEvent="true"
        :moduleSearch="search"
      >
        <template v-slot:item.action="{ item }">
          <div class="d-flex">
              <button   @click="addFileModal=true"> <i class="flaticon-eye " ></i></button>
              <button  @click="addFileModal2=true">  <i class="flaticon2-trash ml-4 text-dark text-md"></i></button>
            <!-- <button class="btn p-0">
              <i class="flaticon2-trash text-dark text-md"></i>
            </button> -->
         
          </div>
        </template>
      </List>
      <b-modal v-model="addFileModal" id="note-modal">
      <template #modal-header>
        <h4>Yeni İl Ekle</h4>
      </template>
      <div class="form-group">
        <label for="aciklama">İl Adı</label>
        <input type="text" class="form-control" id="ilAdi">
      </div>
      <div class="form-group">
        <label for="aciklama">İl Plaka Kodu</label>
        <input type="text" class="form-control" id="ilPlakaKod">
      </div>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addFileModal = false">
            Vazgeç
          </b-button>
          <b-button variant="success" @click="addNewNote" class="ml-4">
            Kaydet
          </b-button>
        </div>
      </template>
    </b-modal>
    <b-modal v-model="addFileModal2" id="note-modal">
      <template #modal-header>
        <h4>İşlem Onayı</h4>
      </template>
      <p>Şehir bilgisi ve bağlı tüm ilçeler & mahalleler silinecektir! Emin misiniz?</p>
      <template #modal-footer>
        <div class="d-flex justify-content-end w-100">
          <b-button variant="light" @click="addFileModal2 = false">
            Vazgeç
          </b-button>
          <b-button variant="danger" @click="addNewNote" class="ml-4">
            Evet
          </b-button>
        </div>
      </template>
    </b-modal>
  </Card>
    </div>
  </template>
  <script>
  export default {
    name: "systemUsers",
    data() {
      return {
        addNewNote: "",
        addFileModal:false,
        addFileModal2:false,
        search: "",
        filters: "",
        showInsertUpdate: false,
        showDetail: false,
        showImportModal: false,
        resetForm: false,
        confirmCreate: false,
        loading: false,
        header: [
        { text: "İsim", value: "fullName" },
          { text: "Şehir Kodu", value: "sehirKodu" },
          { text: "İşlemler", value: "action" },
        ],
        items: [],
        pagination: {},
        meta: {},
        listSelectedItemIds: [],
        selectedItem: "",
        deleteOperationUrl: "",
        deleteIds: "",
      };
    },
    
    created() {
      this.getList();
    },
    methods: {
      getList() {
        this.loading = true;
        this.items = [
        {
            id: "1",
            fullName: "Adana",
            sehirKodu: "1"
          },
          {
            id: "2",
            fullName: "Adıyaman",
            sehirKodu: "2"

          },
          {
            id: "3",
            fullName: "Afyonkarahisar",
            sehirKodu: "3"

          },
          {
            id: "4",
            fullName: "Aksaray",
            sehirKodu: "68"

          },
          {
            id: "5",
            fullName: "Amasya",
            sehirKodu: "5"

          },
          {
            id: "6",
            fullName: "Ankara",
            sehirKodu: "6"

          },
          {
            id: "7",
            fullName: "Antalya",
            sehirKodu: "7"

          },
          {
            id: "8",
            fullName: "Ardahan Tesisi",
            sehirKodu: "75"

          },
          {
            id: "9",
            fullName: "Artvin",
            sehirKodu: "8"

          },
          {
            id: "10",
            fullName: "Aydın",
            sehirKodu: "9"

          },
        ];
        this.totalItems = 3;
        this.pagination = {
          current_page: 1,
          total_pages: 9,
          total_items: 3,
        };
        this.loading = false;
        // this.items = [];
        // this.totalItems = 0;
        // let queryLimit = this.perPage ? this.perPage : 10;
        // let query = `user/list?Page=${
        //   this.page ? this.page : 1
        // }&limit=${queryLimit}`;
        // if (this.search) {
        //   query += `&search=${this.search}`;
        // }
        // this.$dbFunctions
        //   .get(query)
        //   .then((res) => {
        //     if (res.success == true && res.count > 0) {
        //       this.items = res.data;
        //       this.totalItems = res.count;
        //       this.pagination = {
        //         current_page: res.page,
        //         total_pages: res.pageCount,
        //         total_items: res.count,
        //       };
        //     }
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   })
        //   .finally(() => {
        //     this.loading = false;
        //   });
      },
      updatePage(value) {
        this.meta = value;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      },
    },
    watch: {
      meta: {
        handler() {
          this.getList();
        },
        deep: true,
      },
      search: function (params) {
        this.getList();
      },
    },
  };
  </script>
